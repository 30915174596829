<template>
	<div class="about_US">
		<div class="page_banner">
			<img src="@/assets/images/about/about.jpg" />
			<div class="words">
				<p>
					<span>企业荣誉</span>
					争创百年品牌，服务为您
				</p>
			</div>
		</div>
		<MenuLayout />
		<div class="layout profile clearfix">
			<h1 class="enter">
				企业荣誉
			</h1>

			<div class="cont">
				<p>
					建设中...
				</p>
			</div>

		</div>

	</div>

</template>
<script>
import MenuLayout from "@/components/aboutMenu"
export default ({
	components: {
		MenuLayout
	},
});
</script>
